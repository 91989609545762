import About from 'components/aboutfolder/About';

const AboutPage = () => {
  return (
    <>
      <About />
    </>
  )
}

export default AboutPage
